import React, { useState, useEffect, useCallback } from 'react'
import { useParams,useNavigate } from 'react-router-dom'
import { DateDisplay,DateTimeHHmmssDisplay,GetPermissionOther } from 'utils/common'
import XLSX from "xlsx";

import { useDispatch, useSelector } from 'react-redux'
import { selectActivityCPD } from 'redux/selectors'
import { getRegisteredNameList } from 'redux/actions/activityCpd'
import { ApiGetVWEventCheckIn } from 'redux/actions/events'

import styled from 'styled-components'
import Switchs from 'components/form/Switchs'
import { Box, Stack } from '@mui/material'
import Date from 'components/form/Date'
import TabGroupMenu from 'components/containers/TabGroupMenu'
import Table from 'components/common/Table'
import Button from 'components/form/button/Button'
import ButtonOutline from 'components/form/button/ButtonOutline'
import Badge from 'components/containers/Badge'
import RadioButton from 'components/form/RadioButton'

import { LiaCheckSolid, LiaTimesSolid } from 'react-icons/lia'

const Div = styled.div`
  .input-heading {
    color: var(--Gray-700);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  .badge-type-join-style {
    &.join {
      border-color: var(--success-200);
      background: var(--success-50);
      color: var(--success-700);
    }
    &.dont-join {
      border-color: var(--Error-200);
      background: var(--Error-50);
      color: var(--Error-700);
    }
    &.dont-scan {
      border-color: var(--Gray-200);
      background: var(--Gray-50);
      color: var(--Gray-700);
    }
  }
  .disable_compare{
    border: 1px solid #D0D5DD;
    border-radius: 8px;
    background: #D0D5DD;
    padding: 2px 18px;
    color: #000000;
    font-size: 12px;
    text-align: center;
    .font_bold{
      font-weight: 600;
    }
  }
      .box_radio{
    padding: 0px 24px;
    display: flex;
    gap : 0px;
    .radio_css{
        display: flex;
        align-items: center;
    }
    .text_green{
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color : #085D3A;
    }
    .text_red{
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color : #912018;
    }
    .text_normal{
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color : #101828;
    }
}
`
const TableWrapper = styled.div`
  .header-table {
    padding: 26px 24px;
    border-radius: 8px 8px 0 0;
    border: 1px solid var(--Gray-200);
  }
  .heading-table {
    color: var(--Gray-900, #101828);
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
  }
  .sub-heading-table {
    color: var(--Gray-600);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
  }
`

function QRUserListPart({EventId}) {
  // external hook
  const dispatch = useDispatch()
  const { id } = useParams()
  const navigate = useNavigate()
  const [filterDate, setfilterDate] = useState(null)
  const loadExcel = () => {

    console.log('Excel') 

  }

  const limit_data = 10;
  const [total, settotal] = useState(0)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [TableCellData, setTableCellData] = useState([])
  const TableColumnData = [
    {
      Header: 'ลำดับ',
      accessor: 'no',
      disableSortBy: false,
    },
    {
        Header: 'เลขที่สมาชิก',
        accessor: 'user_no',
        disableSortBy: false,
    },
    {
        Header: 'ชื่อ นามสกุล',
        accessor: 'full_name',
        disableSortBy: false,
    },
    {
        Header: 'วันเวลาเข้าร่วมกิจกรรม (QR Check-in)',
        accessor: 'chack_in_date',
        disableSortBy: false,
    },
    {
        Header: 'วันเวลารับคะแนน (QR Check-out)',
        accessor: 'chack_out_date',
        disableSortBy: false,
    },
  ]

  const fetchData = useCallback(
    async (filter) => {

      dispatch(ApiGetVWEventCheckIn(filter)).then(({ type,data_info }) => {
        if(type.endsWith('_SUCCESS')){
          if (data_info.total > 0) {
            const totalPage = Math.ceil(data_info.total / limit_data)
            settotal(data_info.total)
            setTotalPages(totalPage)
            const ThisSkip = data_info.skip == 0 ? 1 : (data_info.skip / limit_data) + 1;

            const newTableCellData = data_info.data.map((v, index) => ({
                no: limit_data * ThisSkip - (9 - index),
                user_no: v.user_no,
                full_name: v.full_name,
                chack_in_date: v.chack_in_date ? DateTimeHHmmssDisplay(v.chack_in_date) : '-',
                chack_out_date: v.chack_out_date ? DateTimeHHmmssDisplay(v.chack_out_date) : '-',   
            }))
            setTableCellData(newTableCellData)
          }else{
            settotal(0);
            setPage(0);
            setTotalPages(0);
            setTableCellData([]);
          }
         
        }
      })

    },
    [dispatch]
  )

  useEffect(() => {
    const filter = {
      skip: 0,
      limit: limit_data,
      event_id : EventId
    }
    fetchData(filter)
  }, [fetchData, limit_data])

  const onChangePaginationAttendeeAttend = async (value) => {
    const filter = {
      skip: (value.page  - 1) * limit_data, 
      limit: limit_data,
      event_id : EventId,
      activity_date : filterDate,
    }
    fetchData(filter)
    setPage(value.page)
  }

  const onfilterDate = (v) => {
    setfilterDate(v)
    const filter = {
        skip: 0,
        limit: limit_data,
        event_id : EventId,
        activity_date : v
      }
      fetchData(filter)
      setPage(1)
  }

  return (
    <Div>
      <Box sx={{ width: 350, mb: 2 }}>
        <div className="input-heading">วันที่จัดกิจกรรม*</div>
        <Date
            className={'input-calendar'}
            value={filterDate}
            onChange={(v) => onfilterDate(v)}
        />
      </Box>


      <TableWrapper>
        <Stack className="header-table" direction="row" justifyContent="space-between" alignItems="center">
          <Box>
            <Stack direction="row" spacing={1}>
              <div className="heading-table">จำนวนรายการ</div>
              <Badge>{total} รายการ</Badge>
            </Stack>
          </Box>

          <Box>
            <Stack direction="row" spacing={2}>
            <ButtonOutline onClick={() => loadExcel()}>Download</ButtonOutline>
            </Stack>
          </Box>
          
        </Stack>
          <Table
            data={TableCellData}
            columns={TableColumnData}
            page={page}
            pageCount={totalPages}
            onStateChange={onChangePaginationAttendeeAttend}
          />
      </TableWrapper>
    </Div>
  )
}

export default QRUserListPart
