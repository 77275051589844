import React, { useState, useEffect, useCallback } from 'react'
import { useParams,useNavigate } from 'react-router-dom'
import { DateDisplay,DateTimeHHmmssDisplay,GetPermissionOther,numberWithCommas } from 'utils/common'
import XLSX from "xlsx";

import { useDispatch, useSelector } from 'react-redux'
import { selectActivityCPD } from 'redux/selectors'
import { getRegisteredNameList } from 'redux/actions/activityCpd'
import { ApiCMSEventStatistics } from 'redux/actions/events'

import styled from 'styled-components'
import Switchs from 'components/form/Switchs'
import { Box, Stack } from '@mui/material'
import Date from 'components/form/Date'
import TabGroupMenu from 'components/containers/TabGroupMenu'
import Table from 'components/common/Table'
import Button from 'components/form/button/Button'
import ButtonOutline from 'components/form/button/ButtonOutline'
import Badge from 'components/containers/Badge'
import RadioButton from 'components/form/RadioButton'

import { LiaCheckSolid, LiaTimesSolid } from 'react-icons/lia'

const Div = styled.div`
  .input-heading {
    color: var(--Gray-700);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  .badge-type-join-style {
    &.join {
      border-color: var(--success-200);
      background: var(--success-50);
      color: var(--success-700);
    }
    &.dont-join {
      border-color: var(--Error-200);
      background: var(--Error-50);
      color: var(--Error-700);
    }
    &.dont-scan {
      border-color: var(--Gray-200);
      background: var(--Gray-50);
      color: var(--Gray-700);
    }
  }
  .disable_compare{
    border: 1px solid #D0D5DD;
    border-radius: 8px;
    background: #D0D5DD;
    padding: 2px 18px;
    color: #000000;
    font-size: 12px;
    text-align: center;
    .font_bold{
      font-weight: 600;
    }
  }
      .box_radio{
    padding: 0px 24px;
    display: flex;
    gap : 0px;
    .radio_css{
        display: flex;
        align-items: center;
    }
    .text_green{
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color : #085D3A;
    }
    .text_red{
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color : #912018;
    }
    .text_normal{
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color : #101828;
    }
}
`
const TableWrapper = styled.div`
  .header-table {
    padding: 26px 24px;
    border-radius: 8px 8px 0 0;
    border: 1px solid var(--Gray-200);
  }
  .heading-table {
    color: var(--Gray-900, #101828);
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
  }
  .sub-heading-table {
    color: var(--Gray-600);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
  }
  .css_remark{
    margin-top: 16px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
`

const DivTable = styled.div`
  width: 100%;
  overflow-x: auto;
  .text_css {
    min-width: 200px;
  }
  .text-bold {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    color: #475467;
  }

  .link {
    color: var(--Primary-700);
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .summary_bg {
    background: var(--Brand-50, #f9f5ff);
  }
  table {
    width: 100%;
    border-collapse: collapse;
    box-shadow: var(--Shadow-xs);
    tr {
      border: 1px solid var(--Gray-200);
    }
    th,
    td {
      padding: 16px 14px;
      text-align: left;
      font-size: 14px;
      color: var(--Gray-600);
      white-space: nowrap;
      background: var(--Base-White);
      align-content: flex-start;
      /*border-bottom: 1px solid var(--Gray-200);*/
    }

    th {
      padding: 12x 14px;
      font-weight: 500;
      background: var(--Gray-50, #f9fafb);
    }
    /*
    tr:hover {
      td {
        background: var(--Table-Bg-Hover);
        color: var(--Table-Font-Hover);
      }
    }
    */
  }

  .content-wrapper {
    text-align: center;
  }

  .pagination {
    margin: 30px 24px;
  }

  .cursor_pointer {
    cursor: pointer;
  }
  .cancel-activity {
    color: var(--COE-Main-CI);
    font-weight: 700;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .status-badge {
    width: fit-content;
    padding: 2px 6px;
    text-align: center;
    font-weight: 500;
    border-radius: 16px;
    color: #475467;
    background: #f5f5f4;
    border: 1px #e7e5e4 solid;

    .status {
      font-size: 0.6rem;
      margin-right: 0.2rem;
      color: #475467;
    }
  }
  .box_config {
    display: flex;
    gap: 5px;
  }
  .box_config_icon {
    display: flex;
    align-items: center;
  }
  .data_text_center {
    text-align: center;
  }
  .data_text_right {
    text-align: right;
  }
  .text_color_error {
    color: #dc3545;
  }
  .width_no {
    text-align: center;
    width: 5%;
  }
  .width_license {
    width: 18%;
  }
  .width_num {
    width: 7%;
  }
`

function EventStatistics({EventId}) {
  // external hook
  const dispatch = useDispatch()
  const { id } = useParams()
  const navigate = useNavigate()
  const [filterDate, setfilterDate] = useState(null)
  const loadExcel = () => {

    const resultToxlsx = TableCellData.map((v,index) => {
        return {
            "ลำดับ" : index + 1,
            "ระดับ" : v.license_type,
            "สาขาโยธา" : numberWithCommas(v.num_civil),
            "สาขาเครื่องกล" : numberWithCommas(v.num_mechanical),
            "สาขาไฟฟ้ากำลัง" : numberWithCommas(v.num_ee_p),
            "สาขาไฟฟ้าสื่อสาร" : numberWithCommas(v.num_ee_c),
            "สาขาอุตสาหการ" : numberWithCommas(v.num_industrial),
            "สาขาสิ่งแวดล้อม" : numberWithCommas(v.num_environmental),
            "สาขาเคมี" : numberWithCommas(v.num_chemical),
            "สาขาเหมืองแร่ งานเหมืองแร่" : numberWithCommas(v.num_mn),
            "สาขาเหมืองแร่ งานโลหการ" : numberWithCommas(v.num_mt),
            "อื่นๆ" : numberWithCommas(v.num_other),
            "รวม" : numberWithCommas(v.total),
        };
    });
    if(resultToxlsx.length > 0){
        const resultTotal = [
            {
                "ลำดับ" : '',
                "ระดับ" : 'รวมทั้งหมด',
                "สาขาโยธา" : TotalSummary('num_civil'),
                "สาขาเครื่องกล" : TotalSummary('num_mechanical'),
                "สาขาไฟฟ้ากำลัง" : TotalSummary('num_ee_p'),
                "สาขาไฟฟ้าสื่อสาร" : TotalSummary('num_ee_c'),
                "สาขาอุตสาหการ" : TotalSummary('num_industrial'),
                "สาขาสิ่งแวดล้อม" : TotalSummary('num_environmental'),
                "สาขาเคมี" : TotalSummary('num_chemical'),
                "สาขาเหมืองแร่ งานเหมืองแร่" : TotalSummary('num_mn'),
                "สาขาเหมืองแร่ งานโลหการ" : TotalSummary('num_mt'),
                "อื่นๆ" : TotalSummary('num_other'),
                "รวม" : TotalSummary('total'),
            }
        ]
        const xlsxData = [...resultToxlsx, ...resultTotal]
        const dataWS = XLSX.utils.json_to_sheet(xlsxData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, dataWS);
        XLSX.writeFile(wb, "สถิติผู้เข้าร่วมกิจกรรม.xlsx");
    }


  }

  const limit_data = 10;
  const [total, settotal] = useState(0)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [TableCellData, setTableCellData] = useState([])
  const TableColumnData = [
    {
        Header: 'ลำดับ',
        accessor: 'no',
        className: 'width_no',
        disableSortBy: false,
    },
    {
        Header: 'ระดับ',
        accessor: 'license_type',
        className: 'width_license',
        disableSortBy: false,
    },
    {
        Header: 'สาขาโยธา',
        accessor: 'num_civil',
        className: 'width_num',
        disableSortBy: false,
    },
    {
        Header: 'สาขาเครื่องกล',
        accessor: 'num_mechanical',
        className: 'width_num',
        disableSortBy: false,
    },
    {
        Header: 'สาขาไฟฟ้ากำลัง',
        accessor: 'num_ee_p',
        className: 'width_num',
        disableSortBy: false,
    },
    {
        Header: 'สาขาไฟฟ้าสื่อสาร',
        accessor: 'num_ee_c',
        className: 'width_num',
        disableSortBy: false,
    },
    {
        Header: 'สาขาอุตสาหการ',
        accessor: 'num_industrial',
        className: 'width_num',
        disableSortBy: false,
    },
    {
        Header: 'สาขาสิ่งแวดล้อม',
        accessor: 'num_environmental',
        className: 'width_num',
        disableSortBy: false,
    },
    {
        Header: 'สาขาเคมี',
        accessor: 'num_chemical',
        className: 'width_num',
        disableSortBy: false,
    },
    {
        Header: 'สาขาเหมืองแร่ งานเหมืองแร่',
        accessor: 'num_mn',
        className: 'width_num',
        disableSortBy: false,
    },
    {
        Header: 'สาขาเหมืองแร่ งานโลหการ',
        accessor: 'num_mt',
        className: 'width_num',
        disableSortBy: false,
    },
    {
        Header: 'อื่นๆ',
        accessor: 'num_other',
        className: 'width_num',
        disableSortBy: false,
    },
    {
        Header: 'รวม',
        accessor: 'total',
        className: 'width_num',
        disableSortBy: false,
    },
  ]

  useEffect(() => {
    const sent_data_to_api = {
        event_id : EventId,
    };
    dispatch(ApiCMSEventStatistics(sent_data_to_api)).then(({ type,data_info }) => {
      if(type.endsWith('_SUCCESS')){
        if(data_info.code == 200){
          if(data_info.all_data){
            setTableCellData(data_info.all_data)
          }
        }
      }
    })

}, [dispatch])


  const onfilterDate = (v) => {
    setfilterDate(v)
    /*
    const filter = {
        skip: 0,
        limit: limit_data,
        event_id : EventId,
        activity_date : v
      }
      fetchData(filter)
      setPage(1)
      */
  }

  const TotalSummary = (type) => {
    if(type == 'num_civil'){
        const total = TableCellData.reduce(
            (accumulator, current) => accumulator + +current.num_civil,
            0
        )
        return numberWithCommas(total)
    }else if(type == 'num_mechanical'){
        const total = TableCellData.reduce(
            (accumulator, current) => accumulator + +current.num_mechanical,
            0
        )
        return numberWithCommas(total)
    }else if(type == 'num_ee_p'){
        const total = TableCellData.reduce(
            (accumulator, current) => accumulator + +current.num_ee_p,
            0
        )
        return numberWithCommas(total)
    }else if(type == 'num_ee_c'){
        const total = TableCellData.reduce(
            (accumulator, current) => accumulator + +current.num_ee_c,
            0
        )
        return numberWithCommas(total)
    }else if(type == 'num_industrial'){
        const total = TableCellData.reduce(
            (accumulator, current) => accumulator + +current.num_industrial,
            0
        )
        return numberWithCommas(total)
    }else if(type == 'num_environmental'){
        const total = TableCellData.reduce(
            (accumulator, current) => accumulator + +current.num_environmental,
            0
        )
        return numberWithCommas(total)
    }else if(type == 'num_chemical'){
        const total = TableCellData.reduce(
            (accumulator, current) => accumulator + +current.num_chemical,
            0
        )
        return numberWithCommas(total)
    }else if(type == 'num_mn'){
        const total = TableCellData.reduce(
            (accumulator, current) => accumulator + +current.num_mn,
            0
        )
        return numberWithCommas(total)
    }else if(type == 'num_mt'){
        const total = TableCellData.reduce(
            (accumulator, current) => accumulator + +current.num_mt,
            0
        )
        return numberWithCommas(total)
    }else if(type == 'num_other'){
        const total = TableCellData.reduce(
            (accumulator, current) => accumulator + +current.num_other,
            0
        )
        return numberWithCommas(total)
    }else if(type == 'total'){
        const total = TableCellData.reduce(
            (accumulator, current) => accumulator + +current.total,
            0
        )
        return numberWithCommas(total)
    }else{
        return numberWithCommas(0)
    }
    
  }
  return (
    <Div>


      <Stack className="header-table" direction="row" justifyContent="space-between" alignItems="center">
            <Box sx={{ width: 350, mb: 2 }}>
                <div className="input-heading">ค้นหาด้วยวันที่</div>
                <Date
                    className={'input-calendar'}
                    value={filterDate}
                    onChange={(v) => onfilterDate(v)}
                />
            </Box>

            <Box>
                <Stack direction="row" spacing={2}>
                    <ButtonOutline onClick={() => loadExcel()}>Download</ButtonOutline>
                </Stack>
            </Box>
          
        </Stack>


      <TableWrapper>
        <div>
            <DivTable>
                <table>
                    <thead>
                        <tr>
                            {TableColumnData.map((column) => (
                                <th key={column.accessor} className={`${column.className}`}>
                                    <div>{column.Header ? column.Header : ''}</div>
                                </th>
                            ))}
                        </tr>
                        {TableCellData?.map((item, index) => (
                            <tr key={index}>
                                <td className="data_text_center">{index + 1}</td>
                                <td >{item.license_type}</td>
                                <td className="data_text_left">{numberWithCommas(item.num_civil)}</td>
                                <td className="data_text_left">{numberWithCommas(item.num_mechanical)}</td>
                                <td className="data_text_left">{numberWithCommas(item.num_ee_p)}</td>
                                <td className="data_text_left">{numberWithCommas(item.num_ee_c)}</td>
                                <td className="data_text_left">{numberWithCommas(item.num_industrial)}</td>
                                <td className="data_text_left">{numberWithCommas(item.num_environmental)}</td>
                                <td className="data_text_left">{numberWithCommas(item.num_chemical)}</td>
                                <td className="data_text_left">{numberWithCommas(item.num_mn)}</td>
                                <td className="data_text_left">{numberWithCommas(item.num_mt)}</td>
                                <td className="data_text_left">{numberWithCommas(item.num_other)}</td>
                                <td className="data_text_left">{numberWithCommas(item.total)}</td>
                            </tr>
                        ))}
                        <tr>
                            <th ></th>
                            <th className="text-bold">รวมทั้งหมด</th>
                            <th className="data_text_left">{TotalSummary('num_civil')}</th>
                            <th className="data_text_left">{TotalSummary('num_mechanical')}</th>
                            <th className="data_text_left">{TotalSummary('num_ee_p')}</th>
                            <th className="data_text_left">{TotalSummary('num_ee_c')}</th>
                            <th className="data_text_left">{TotalSummary('num_industrial')}</th>
                            <th className="data_text_left">{TotalSummary('num_environmental')}</th>
                            <th className="data_text_left">{TotalSummary('num_chemical')}</th>
                            <th className="data_text_left">{TotalSummary('num_mn')}</th>
                            <th className="data_text_left">{TotalSummary('num_mt')}</th>
                            <th className="data_text_left">{TotalSummary('num_other')}</th>
                            <th className="data_text_left">{TotalSummary('total')}</th>
                        </tr>
                    </thead>
                </table>
            </DivTable>
        </div>
        <div className="css_remark">หมายเหตุ : สถิติระดับใบอนุญาต คัดแยกจากใบอนุญาตสูงสุด ทุกสถานะ กรณีมีใบอนุญาตหลายใบ จะนับจากใบอนุญาตล่าสุด (ใบที่ออกให้ครั้งแรกล่าสุด)</div>
      </TableWrapper>
    </Div>
  )
}

export default EventStatistics
